import React from 'react';

const LogoIcon = () => (
  <svg
    className='logo'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width='297.553px'
    height='297.546px'
    viewBox='-13.776 0 297.553 297.546'>
    <g>
      <g>
        <g>
          <g>
            <g>
              <defs>
                <path
                  id='SVGID_1_'
                  d='M202.501,3.99v55.353c0,3.516-0.012,6.471-4.113,8.529L67.5,133.313l62.461,31.229
							c3.633,1.943,3.556,3.273,0.144,4.967l-58.876,29.438c-3.259,1.523-4.195,1.547-7.61-0.076L2.744,168.436
							C0.799,167.427,0,166.339,0,162.224v-58.83c0-1.996,0.818-4.336,3.203-5.431l194.338-97.17
							C200.581-0.668,202.501-0.382,202.501,3.99'
                />
              </defs>
              <g>
                <path
                  fill='#2F5FAC'
                  d='M129.961,164.542c3.633,1.944,3.556,3.274,0.144,4.967l-58.876,29.439
							c-3.259,1.522-4.195,1.545-7.61-0.078L0,167.063l67.5-33.75L129.961,164.542'
                />
                <polygon
                  fill='#128CFF'
                  points='0,99.563 0,167.063 67.5,133.313 						'
                />
                <path
                  fill='#8FD6FF'
                  d='M202.501,3.99v55.353c0,3.516-0.012,6.471-4.113,8.529L67.5,133.313L0,99.563L197.542,0.792
							C200.581-0.668,202.501-0.382,202.501,3.99'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <g>
              <defs>
                <path
                  id='SVGID_3_'
                  d='M67.5,293.556v-55.354c0-3.517,0.011-6.472,4.11-8.527l130.89-65.442l-62.462-31.23
							c-3.634-1.944-3.558-3.273-0.146-4.966l58.876-29.439c3.262-1.523,4.197-1.545,7.613,0.077l60.874,30.436
							c1.943,1.009,2.742,2.097,2.742,6.213v58.83c0,1.994-0.82,4.336-3.201,5.432l-194.34,97.17
							C69.419,298.212,67.5,297.928,67.5,293.556'
                />
              </defs>
              <g>
                <path
                  fill='#2F5FAC'
                  d='M140.039,133.001c-3.633-1.944-3.558-3.273-0.146-4.966l58.876-29.439
							c3.263-1.523,4.197-1.545,7.613,0.077l63.618,31.809l-67.5,33.75L140.039,133.001'
                />
                <polygon
                  fill='#128CFF'
                  points='270.001,197.981 270.001,130.482 202.501,164.232 						'
                />
                <path
                  fill='#8FD6FF'
                  d='M67.5,293.556v-55.354c0-3.517,0.011-6.472,4.11-8.527l130.89-65.442l67.5,33.749l-197.542,98.77
							C69.419,298.212,67.5,297.928,67.5,293.556'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default LogoIcon;
