import React, { Component } from 'react';

class Footer extends Component {
  state = {};
  render() {
    return (
      <div className='footer'>
        <p align='center'>Designed and Built by Shannon Lau, 2024.</p>
      </div>
    );
  }
}

export default Footer;
